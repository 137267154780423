const sortOffers = offers =>
	offers.reduce(
		(acc, offer) => {
			if (acc[offer.category]) {
				acc[offer.category].push(offer)
				return acc
			}

			acc.other.push(offer)
			return acc
		},
		{
			all: offers,
			engineering: [],
			product: [],
			design: [],
			marketing: [],
			operations: [],
			other: [],
		},
	)

export default sortOffers
