export const members = [
	{
		id: 0,
		name: 'Bogdan Nesvit',
		position: 'CEO',
		photo: '/images/mainPage/ceo.png',
		linkedIn: 'https://www.linkedin.com/in/bogdannesvit/',
	},
	{
		id: 1,
		name: 'Anatolii Kasianov',
		position: 'CTO',
		photo: '/images/mainPage/cto.png',
		linkedIn: 'https://www.linkedin.com/in/anatolii-kasianov/',
	}
]
