import { Box, useConst } from '@chakra-ui/react'
import React, { useCallback, useState } from 'react'
import { FixedHeader } from '../../components/common/fixedHeader'
import { MainFooter } from '../../components/common/mainFooter'
import offers from '../../redux/initialStates/offers'
import sortOffers from '../../utils/sortOffers'
import CareersBanner from './components/careers-banner'
import CareersPositions from './components/careers-positions'

const CareersPage = () => {
	const [activeCategory, setActiveCategory] = useState('all')

	const sortedOffers = useConst(() => sortOffers(offers))

	const onSetCategory = useCallback(category => setActiveCategory(category), [])

	return (
		<Box bg="#0C0C0C">
			<FixedHeader contact />
			<CareersBanner />
			<CareersPositions
				activeCategory={activeCategory}
				sortedOffers={sortedOffers}
				onSetCategory={onSetCategory}
			/>
			<MainFooter careers={false} />
		</Box>
	)
}

export default CareersPage
