import React from 'react'

import './styles.scss'

const LogoIcon = ({ windowWidth, onClick }) => {
	return (
		<>
			<img
				onClick={onClick}
				src={
					windowWidth < 481
						? '/images/common/logo-mobile.png'
						: '/images/common/logo-desktop.png'
				}
				alt="logo-icon"
				className="logo-icon"
			/>
		</>
	)
}

export { LogoIcon }
