import React from 'react'
import { SocialNetworks } from '../../../constants/links'
import './styles.scss'

const MainFooter = () => {
	return (
		<>
			<footer className="page-footer">
				<div className="footer-top contact-info">
					<a className="anchor" id="contact"></a>
					<h2 className="contact-title">Let’s connect</h2>
					<p className="contact-text">
						Feel free to reach out if you want to collaborate with us, or simply
						have a chat
					</p>
					<div className="contact-link">
						<a href="mailto:info@hwfantasylimited.com">
							Contact Us
							<img src="/images/mainPage/slider-arrow.svg" alt="slider-arrow" />
						</a>
					</div>
				</div>
				<div className="footer-bottom">
					{/* <ul className="social-list"> */}
					{/* 	<li> */}
					{/* 		<a href={SocialNetworks.TWITTER}> */}
					{/* 			<img src="/images/charity/twitter-icon.svg" alt="twitter" /> */}
					{/* 		</a> */}
					{/* 	</li> */}
					{/* 	<li> */}
					{/* 		<a href={SocialNetworks.INSTAGRAM}> */}
					{/* 			<img src="/images/charity/instagram-icon.svg" alt="instagram" /> */}
					{/* 		</a> */}
					{/* 	</li> */}
					{/* </ul> */}
					<div className="copywriting-text">
						<p>
							HW Fantasy Limited © {new Date().getFullYear()}
              <br/>
              46 Lykavitou, Egkomi, 2401, Nicosia, Cyprus
							{/* <img src="/images/charity/ukraine.svg" alt="ukraine" /> Ukraine */}
						</p>
					</div>
				</div>
			</footer>
		</>
	)
}

export { MainFooter }
