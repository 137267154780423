export const teamHW = Object.freeze([
	{
		name: 'Bogdan Nesvit',
		position: 'CEO',
		imgSrc: 'images/charity/team/bogdan.jpg',
		link: 'https://www.linkedin.com/in/bogdannesvit',
	},
	{
		name: 'Anatolii Kasiianov',
		position: 'CTO',
		imgSrc: 'images/charity/team/Anatolii.jpg',
		link: 'https://www.linkedin.com/in/anatolii-kasianov',
	},
	{
		name: 'Anna Nesvit',
		position: 'Art curator',
		imgSrc: 'images/charity/team/Anna.jpg',
		link: 'https://www.linkedin.com/in/anna-kravinskaya-37316b12a',
	},
	// {
	// 	name: 'Illia Berestovskyi',
	// 	position: 'Product Manager',
	// 	imgSrc: 'images/charity/team/Illia.jpg',
	// 	link: 'https://www.linkedin.com/in/berestovskyi',
	// },
	// {
	// 	name: 'Viktoriia Kyrylenko',
	// 	position: 'Product Manager',
	// 	imgSrc: 'images/charity/team/Viktoriia.jpg',
	// 	link: 'https://www.linkedin.com/in/viktoriia-kyrylenko-1bb471180',
	// },
	// {
	// 	name: 'Mykhailo Alekseiev',
	// 	position: 'Frontend Developer',
	// 	imgSrc: 'images/charity/team/Michael.jpg',
	// 	link: 'https://www.linkedin.com/in/mykhailo-alekseiev-59b2811a9/',
	// },
	{
		name: 'Yelyzaveta Popova',
		position: 'Art Lead',
		imgSrc: 'images/charity/team/Liza.jpg',
		link: 'https://www.linkedin.com/in/yelyzaveta-popova-1873a51a0',
	},
	{
		name: 'Ziong Le',
		position: 'Engineering Manager',
		imgSrc: 'images/charity/team/Ziong Le.jpg',
		link: 'https://www.linkedin.com/in/lezinx',
	},
	{
		name: 'Poollotto Finance',
		position: 'Collaborator',
		imgSrc: 'images/charity/team/poollotto.png',
		link: 'https://linktr.ee/PLTLotto',
	},
	{
		name: 'Digital Media Guru',
		position: 'Collaborator',
		imgSrc: 'images/charity/team/media-guru.png',
		link: 'https://taplink.cc/digitalmedia.cool',
	},
	{
		name: 'Port Agency',
		position: 'Collaborator',
		imgSrc: 'images/charity/team/port-agency.png',
		link: 'https://www.instagram.com/p/CadIeRTtOj2/?utm_source=ig_web_copy_link',
	},
	{
		name: 'Obrio',
		position: 'Collaborator',
		imgSrc: 'images/charity/team/obrio.png',
		link: 'https://www.linkedin.com/company/obrio-genesis/',
	},
	{
		name: 'F1V',
		position: 'Collaborator',
		imgSrc: 'images/charity/team/f1v.png',
		link: 'https://www.linkedin.com/company/flyer-one-vc/',
	},
	{
		name: 'Headway',
		position: 'Collaborator',
		imgSrc: 'images/charity/team/headway.png',
		link: 'https://apps.get-headway.com',
	},
	{
		name: 'Others',
		imgSrc: '',
		link: 'https://www.linkedin.com/company/holywater/',
	},
])
