import React from 'react'
import { Flex, Image, LinkBox, LinkOverlay } from '@chakra-ui/react'
import { FixedHeader } from '../../components/common/fixedHeader'
import { SectionAbout } from '../../components/mainPage/sectionAbout'
import { SectionCredibility } from '../../components/mainPage/sectionCredibility'
import { SectionMedia } from '../../components/mainPage/sectionMedia'
import { MainFooter } from '../../components/common/mainFooter'
import './styles.scss'
import { Link as LinkR } from 'react-router-dom'

const MainNewPage = () => {
	return (
		<div className="page-container">
			<header className="page-header">
				<FixedHeader products about contact />
				<div className="header-promo">
					<Flex alignItems="center" gap="10px">
						<p className="promo-hello">Hello there </p>
						<Image src="images/mainPage/hand.svg" alt="hand" />
					</Flex>
					<h2 className="promo-title">
						WE CREATE INNOVATIVE TECH PRODUCTS USED BY 10+ MILLION PEOPLE
					</h2>
					<p className="promo-text">
						We are highly motivated team that is building, measuring, and
						learning fast. That’s why our products constantly appear in top
						charts.
					</p>
				</div>
			</header>
			<main className="page-main">
				<section className="section section-products">
					<a className="anchor" id="products"></a>
					<h2 className="section-title">Our Products</h2>
					{/* <LinkBox as="div" className="section-product product-nft"> */}
					{/* 	<div className="product-image"> */}
					{/* 		<LinkOverlay as={LinkR} to="/save-ukraine"> */}
					{/* 			<img src="/images/mainPage/nft.jpg" alt="nft" /> */}
					{/* 		</LinkOverlay> */}
					{/* 	</div> */}
					{/* 	<div> */}
					{/* 		<div className="product-tag"> */}
					{/* 			<ul className="product-tag"> */}
					{/* 				<li>Charity</li> */}
					{/* 				<li>NFT</li> */}
					{/* 			</ul> */}
					{/* 		</div> */}
					{/* 		<h4 className="product-title"> */}
					{/* 			<span className="hashtag">#WorldSaveUkraine</span> NFT */}
					{/* 			collection */}
					{/* 		</h4> */}
					{/* 		<p className="product-desc"> */}
					{/* 			We united <span>500+ Ukrainian artists</span> who created */}
					{/* 			artworks for our charity NFT collection. Our goal is to{' '} */}
					{/* 			<span>raise $1M</span> and donate all proceeds to the Ukrainian */}
					{/* 			government. */}
					{/* 		</p> */}
					{/* 	</div> */}
					{/* </LinkBox> */}

					<LinkBox as="div" className="section-product product-fantasy">
						<div className="product-image">
							{/* <LinkOverlay
								isExternal
								href="https://apps.apple.com/mm/app/my-fantasy-choose-your-story/id1491717191 "
							> */}
								<img src="/images/mainPage/my-fantasy.jpg" alt="my-fantasy" />
							{/* </LinkOverlay> */}
						</div>
						<div>
							<div className="product-tag">
								<ul className="product-tag">
									<li>INTERACTIVE STORIES GAME</li>
								</ul>
							</div>
							<h4 className="product-title">My fantasy</h4>
							<ul className="product-info">
								<li>#1 interactive stories game in Australia</li>
								<li>top 5 interactive stories game worldwide</li>
							</ul>
							<p className="product-desc">
								We are building a Netflix for interactive stories. Our mission
								is to create a game where our users can embrace and fulfill any
								fantasy through interactive stories.
							</p>
						</div>
					</LinkBox>
					{/* <div className="section-product product-web"> */}
					{/* 	<div className="product-image"> */}
					{/* 		<img src="/images/mainPage/web.jpg" alt="web" /> */}
					{/* 	</div> */}
					{/* 	<div> */}
					{/* 		<div className="product-tag"> */}
					{/* 			<ul className="product-tag"> */}
					{/* 				<li>Blockchain game</li> */}
					{/* 			</ul> */}
					{/* 		</div> */}
					{/* 		<h4 className="product-title">WEB 3</h4> */}
					{/* 		<p className="product-desc"> */}
					{/* 			Innovation is one of our core principles. That`s why we are */}
					{/* 			currently developing a blockchain game leveraging our expertise */}
					{/* 			in game dev, game design, and interactive storytelling. */}
					{/* 		</p> */}
					{/* 		<br /> */}
					{/* 		<p className="product-desc"> */}
					{/* 			Our vision is to create utility for PFPs by giving the owners an */}
					{/* 			opportunity to monetize them. */}
					{/* 		</p> */}
					{/* 	</div> */}
					{/* </div> */}
					<LinkBox as="div" className="section-product product-passion">
						<div className="product-image">
							{/* <LinkOverlay
								isExternal
								href="https://apps.apple.com/us/app/passion-romance-fiction-books/id1568149521"
							> */}
								<img src="/images/mainPage/passion.jpg" alt="passion" />
							{/* </LinkOverlay> */}
						</div>
						<div>
							<div className="product-tag">
								<ul className="product-tag">
									<li>ROMANTIC BOOKS APP</li>
								</ul>
							</div>
							<h4 className="product-title">Passion</h4>
							<p className="product-desc">
								Romantic books app is the world of best romance for all tastes,
								which will make every reading evening unforgettable.
							</p>
						</div>
					</LinkBox>
				</section>
				<SectionAbout />
				{/* <SectionMedia /> */}
				{/* <SectionCredibility /> */}
			</main>
			<MainFooter />
		</div>
	)
}

export { MainNewPage }
