export const nftList = Object.freeze([
	{
		mediaSrc: 'images/charity/nfts/nft1.png',
		title: '«Time for changes»',
		author: 'Interesni Kazki',
		description: `Vladimir Manzhos artist/ muralist since 1999, based in Kyiv, Ukraine. 
In 2005 Waone co-founded Interesni Kazki, an artist duo.  Next 10 years the duo worked all around the world, from Australia to Mexico and from San Francisco to Moscow.Their murals are full of fairy characters, fine details, smooth gradients, symbols and stories. Since May 2016 Waone has been working as a single artist..
		`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft2.png',
		title: 'Untitled',
		author: 'Lilit Sarkisian',
		description: `Lilit Sarkisian is an artist and illustrator from Ukraine. She was nominated by the International Committee of the World Bloggers Awards for the Golden Like Award for "The Best Art Blogger in Europe."
		`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft3.png',
		title: '«Не дай бог»',
		author: 'Valentyn Metyk',
		description: `An avantgarde Ukrainian artist.  Lives and works in Kyiv. Love the process of turning ideas into something more. Famous for his ‘blue people’ paintings. `,
	},
	{
		mediaSrc: 'images/charity/nfts/nft4.png',
		title: 'Untitled',
		author: 'Masha Shubina ',
		description: `Pinchuck Prize award 2009, Masha Shubina is a free and cheerful ultra-contemporary artist. Resides in Kyiv. Masha Shubina is ranked among the Top 1,000 in Ukraine.
		`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft5.png',
		title: '«Ukrainian nation»',
		author: 'Daniel Skripnik',
		description: `Ukrainian artist, collaborated with famous artists like The Weeknd and Imagine Dragons, with Adobe and Billie Eilish. Symbol of his works is sunflower that visualise human emotions.
		`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft6.png',
		title: 'Untitled',
		author: 'Samborsky Yevhen',
		description: `Ukrainian artist from Ivano-Frankivsk. In 2012 was awarded with 1st prize at the competition for young Ukrainian artists MUHI`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft7.png',
		title: '«#SaveUkraine»',
		author: 'Vladimir Gulich',
		description: `ArtZebs mixed media artist has unique style in combining digital drawing with modern media. Participant and founder international symposia of contemporary art
		`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft8.png',
		title: '«Save Ukraine»',
		author: 'EVM ART',
		description: `Winner of 5 international awards in visual arts.`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft9.png',
		title: '«Shelter, landscape»',
		author: 'Pavlo Makov',
		description: `Ukrainian artist and a member of the Royal Society of Painters-Printmakers.`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft10.png',
		title: '«Shelter, landscape»',
		author: 'Tiberiy Silvashy',
		description: `Kyiv-based leader of neo-abstraction school of Ukrainian modernists. He is also a founder of the "Picturesque Reserve" art group.`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft11.png',
		title: '«KONDAKOV_01»',
		author: 'Alexey Kondakov',
		description: `Kyiv-based artist that uses surrealistic documentary photography to place figures from classical artwork into modern life. `,
	},
	{
		mediaSrc: 'images/charity/nfts/nft12.png',
		title: '«KONDAKOV_02»',
		author: 'Alexey Kondakov',
		description: `Kyiv-based artist that uses surrealistic documentary photography to place figures from classical artwork into modern life. `,
	},
	{
		mediaSrc: 'images/charity/nfts/nft13.png',
		title: '«Nowaday 2022»',
		author: 'Petro Bevza',
		description: `Ukrainian artist, lives and works in Kyiv and Vyshneve. Conducter 50+ personal exhibitions and is hosted in selected museums.`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft14.png',
		title: '«Earth»',
		author: 'Roman Mykhailov',
		description: `Ukrainian artist from Chuhuiv. He works with painting, graphics, sculpture, video art, and installation.`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft15.png',
		title: '«#SaveUkraine»',
		author: 'Masha Shubina',
		description: `Representative of hyper-realism. Her works with enhanced chiaroscuro resemble pictures from glossy publications. Participant of the Kyiv Biennale Arsenal.`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft16.png',
		title: '«Yellow meadow»',
		author: 'Sai Oleksiy',
		description: `1993 Graduated from Kiev college of industrial arts, grafic designer 2001 Graduated from Ukrainian academy of arts and architecture, grafic artist.`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft17.png',
		title: '«Kyiv»',
		author: 'Sestry Feldman',
		description: `Feldman Sisters are known for the graffiti on the streets of Kyiv. Having developed a unique style, they create bright and recognizable pieces of art.`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft18.png',
		title: '«No war»',
		author: 'Sestry Feldman',
		description: `Feldman Sisters are known for the graffiti on the streets of Kyiv. Having developed a unique style, they create bright and recognizable pieces of art.`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft19.png',
		title: `«I can't stand»`,
		author: 'RAMAN KAMINSKI',
		description: `Raman Kaminsky is an artist from Belarus who has lived in Ukraine since 2018. I work in various media - painting, installation, graphics`,
	},
	{
		mediaSrc: 'images/charity/nfts/nft20.png',
		title: `«#SaveUkraine»`,
		author: 'Masha Shubina ',
		description: `Representative of hyper-realism. Her works with enhanced chiaroscuro resemble pictures from glossy publications. Participant of the Kyiv Biennale Arsenal.`,
	},
])
