import React, { useEffect } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'

import CareersPage from './screens/careers'
import MainNewPage from './screens/mainNewPage'

import './styles/styles.scss'
import CharityPage from './screens/charityPage'

import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'

const App = () => {
	const { pathname } = useLocation()

	useEffect(() => {
		window.scrollTo(0, 0)

		switch (pathname) {
			case '/': {
				document.title = 'Holy Water'
				return
			}
			case '/company': {
				document.title = 'Holy Water | Company'
				return
			}
			case '/team': {
				document.title = 'Holy Water | Team'
				return
			}
			case '/careers': {
				document.title = 'Holy Water | Careers'
				return
			}
			case '/products': {
				document.title = 'Holy Water | Products'
				return
			}
			case '/contact-holy-water': {
				document.title = 'Holy Water | Contact Holy Water'
				// return
			}
			// case '/save-ukraine': {
			// 	document.title = 'World save Ukraine'
			// }
		}
	}, [pathname])

	useEffect(() => {
		window.history.scrollRestoration = 'manual'
	}, [])

	return (
		<>
			<Routes>
				{/* <Route path="/" element={<MainPage />} /> */}
				<Route path="/" element={<MainNewPage />} />
				{/* <Route path="/company" element={<CompanyPage />} /> */}
				{/* <Route path="/team" element={<TeamPage />} /> */}
				<Route path="/careers" element={<CareersPage />} />
				{/* <Route path="/products" element={<ProductsPage />} /> */}
				{/* <Route path="/products/:productName" element={<ProductPage />} /> */}
				{/* <Route path="/contact-holy-water" element={<ContactHolyWaterPage />} /> */}
				{/* <Route path="/save-ukraine" element={<CharityPage />} /> */}
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>
		</>
	)
}

export default App
