import { LinkBox, LinkOverlay } from '@chakra-ui/react'
import React from 'react'
import { FreeMode, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Video from '../../teamPage/videoSlide/video/team-video.mp4'
import { members } from './data'
import './styles.scss'

const SectionAbout = () => {
	return (
		<section className="section section-about">
			<a className="anchor" id="about"></a>
			<div className="section-title">
				<h2>About Us</h2>
				<p>
					Our goal is to build a unicorn <br /> ($1B valuation) by 2026{' '}
					<img src="images/mainPage/unicorn.svg" alt="unicorn" />
				</p>
			</div>
			<p className="section-text">
				We were established in early 2020.
			</p>
			<div className="about-info">
				<div className="about-image">
					<img src="/images/mainPage/zapovedi.png" alt="zapovedi" />
				</div>
				<h4 className="about-title">
					SINCE THEN WE`VE MANAGED TO LAUNCH PRODUCTS WHICH REGULARLY APPEAR IN APPSTORE TOP CHARTS AND ASSEMBLED A STRONG TEAM OF 50+ PEOPLE.
				</h4>
			</div>
			<h3 className="section-subtitle">Leadership</h3>
			<Swiper
				slidesPerView={'auto'}
				speed={1000}
				freeMode={true}
				spaceBetween={50}
				className="about-slider"
				pagination={{
					type: 'progressbar',
				}}
				modules={[FreeMode, Pagination]}
			>
				{members.map(member => (
					<SwiperSlide key={member.id} style={{ width: 'auto' }}>
						<LinkBox as="div" className="about-card">
							<div className="about-photo">
								{/* <LinkOverlay isExternal href={member.linkedIn}> */}
									<img src={member.photo} alt={member.name} />
								{/* </LinkOverlay> */}
							</div>
							<div className="about-name">
								<h4>{member.name}</h4>
								<span>{member.position}</span>
							</div>
						</LinkBox>
					</SwiperSlide>
				))}
				{/* <SliderArrowButton next={false} />
				<SliderArrowButton /> */}
			</Swiper>
			<div className="about-video">
				<p className="section-text">
					Our global mission is to build a company where we will be happy while
					exploring our full potential and reaching our most ambitious and
					passionate dreams by building successful products together.
				</p>
				<video
					autoPlay="autoplay"
					loop
					muted
					src={Video}
					type="video/mp4"
					playsInline
				/>
			</div>
		</section>
	)
}

export { SectionAbout }
