export const collaborators = Object.freeze([
	{
		link: 'https://www.instagram.com/p/Cah__AtNNsm/?utm_source=ig_web_copy_link',
		name: 'museumsofukraine',
		imgSrc: '/images/charity/contributers/museumsofukraine.png',
		info: 'Museum',
	},
	{
		link: 'https://www.instagram.com/p/CahkI3rJuEN/?utm_source=ig_web_copy_link',
		name: 'Dima Dubilet',
		imgSrc: '/images/charity/contributers/dima-dubilet.png',
		info: 'Public Figure',
	},
	{
		link: 'https://twitter.com/Zeneca_33/status/1499911254965690374?s=20&t=Mil-OvtcNFVIK7dXl1FwyA',
		name: 'Zeneca_33',
		imgSrc: '/images/charity/contributers/zeneca-33.png',
		info: 'NFT blogger',
	},
	{
		link: 'https://twitter.com/popartcats/status/1499775008536768523?s=20&t=Mil-OvtcNFVIK7dXl1FwyA',
		name: 'Popartcats',
		imgSrc: '/images/charity/contributers/popartcats.png',
		info: 'NFT blogger',
	},
	{
		link: 'https://twitter.com/cryptodrftng/status/1500056297907138563?s=20&t=Mil-OvtcNFVIK7dXl1FwyA',
		name: 'Alona | UkraineDAO',
		imgSrc: '/images/charity/contributers/aloha-ukraine-dao.png',
		info: 'NFT blogger',
	},
	{
		link: 'https://twitter.com/ItsAdamtastic/status/1499732977068163073?s=20&t=Mil-OvtcNFVIK7dXl1FwyA',
		name: 'ItsAdamstatic',
		imgSrc: '/images/charity/contributers/its-adamstatic.svg',
		info: 'NFT blogger',
	},
	{
		link: 'https://twitter.com/HolyWaterUA/status/1499739238937595905?s=20&t=iFQExi3VsV9qajJJra7fFg',
		name: 'DenManu2',
		imgSrc: '/images/charity/contributers/den-manu-2.png',
		info: 'NFT blogger',
	},
	{
		link: 'https://www.facebook.com/nasdaily',
		name: 'Nas Daily',
		imgSrc: '/images/charity/contributers/nas-daily.png',
		info: 'Public figure',
	},
])
