import { Box, Flex, Heading, Image, Link, Text } from '@chakra-ui/react'
import React from 'react'
import { NavLink } from 'react-router-dom'

const CareersBanner = () => {
	return (
		<Box padding={{ base: '139px 30px 120px 20px', lg: '182px 0 250px 160px' }}>
			<Link as={NavLink} to={-1}>
				<Flex gap={1} alignItems="center" mb={['40px', null, '53px']}>
					<Image
						src="/images/careers/arrow.svg"
						alt="arrow"
						transform="rotate(-125deg)"
					/>
					<Text fontSize={['14px', '20px']} lineHeight={['17px', '24px']}>
						Back
					</Text>
				</Flex>
			</Link>
			<Heading
				mb={6}
				fontSize={['40px', null, '100px']}
				lineHeight={{ md: '140px' }}
			>
				Careers
			</Heading>
			<Text
				fontFamily="e-Ukraine-Thin"
				color="#B5B5B5"
				fontSize={{ md: '28px' }}
				maxWidth={{ md: '813px' }}
				lineHeight={{ md: '40px' }}
				marginBottom={{ md: '40px' }}
			>
				Our global mission is to build a company where we will be happy while
				exploring our full potential and reaching our most ambitious and
				passionate dreams by building successful products together.
			</Text>
		</Box>
	)
}

export default CareersBanner
