import { Link } from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { Link as LinkS, animateScroll as scroll } from 'react-scroll'
import { LogoIcon } from '../'
import useWindowSize from '../../../hooks/useWindowSize'
import './styles.scss'

const FixedHeader = ({ products, credibility, about, contact }) => {
	const navigate = useNavigate()
	const { pathname } = useLocation()
	const { width } = useWindowSize()

	const isMobile = useMemo(() => width < 768, [width])

	const [scrollNav, setScrollNav] = useState(false)

	const handleClickLogo = () => {
		if (pathname === '/') {
			scroll.scrollToTop()
			return
		}
		return navigate('/')
	}

	const changeNav = useCallback(() => {
		if (window.scrollY >= 80) {
			setScrollNav(true)
			return
		}

		setScrollNav(false)
	}, [])

	useEffect(() => {
		window.addEventListener('scroll', changeNav)

		return () => {
			window.removeEventListener('scroll', changeNav)
		}
	}, [])

	return (
		<div className="header-fixed">
			{/* <RouterLink to="/save-ukraine" className="top-bar"> */}
			{/* 	<h3 className="top-bar-text">World Save Ukraine. Mint to donate</h3> */}
			{/* 	<img src="/images/mainPage/slider-arrow.svg" alt="slider-arrow" /> */}
			{/* </RouterLink> */}
			<nav className={`header-nav ${scrollNav && isMobile ? 'scrolled' : ''}`}>
				<div
					className={`header-logo ${scrollNav && isMobile ? 'scrolled' : ''}`}
				>
					<LogoIcon windowWidth={30} onClick={handleClickLogo} />
				</div>
				<ul className="header-menu">
					{products && (
						<li>
							<Link
								display={{ base: 'none', md: 'block' }}
								as={LinkS}
								to="products"
								spy={true}
								smooth={true}
								duration={1000}
							>
								Products
							</Link>
						</li>
					)}
					{about && (
						<li>
							<Link
								display={{ base: 'none', md: 'block' }}
								as={LinkS}
								to="about"
								spy={true}
								smooth={true}
								duration={1000}
							>
								About
							</Link>
						</li>
					)}
					{credibility && (
						<li>
							<Link
								display={{ base: 'none', md: 'block' }}
								as={LinkS}
								to="credibility"
								spy={true}
								smooth={true}
								duration={1000}
							>
								Credibility
							</Link>
						</li>
					)}
					{contact && (
						<li>
							<Link
								display="block"
								as={LinkS}
								to="contact"
								spy={true}
								smooth={true}
								duration={1000}
							>
								Contact
							</Link>
						</li>
					)}
				</ul>
			</nav>
		</div>
	)
}

export { FixedHeader }
