import React from 'react'

import './styles.scss'

const BurgerIcon = ({ toggleBurgerMenu, windowWidth }) => {
	return (
		<div className="burger-icon" onClick={toggleBurgerMenu}>
			<img
				src={
					windowWidth < 481
						? '/images/common/close-icon.png'
						: '/images/common/close-icon-desktop.png'
				}
				alt="burger-icon"
			/>
		</div>
	)
}

export { BurgerIcon }
