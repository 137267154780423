export const textStyles = {
	baseStyle: {
		fontSize: '16px',
		lineHeight: '150%',
		color: '#fff',
		fontFamily: 'e-Ukraine',
	},
	variants: {
		active: {
			textDecoration: 'underline',
			fontWeight: 'Bold',
		},
		default: {},
	},
	defaultProps: {
		variant: 'default',
	},
}
