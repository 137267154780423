import {
	Box,
	Flex,
	Heading,
	Image,
	LinkBox,
	LinkOverlay,
	Table,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react'
import React from 'react'
import { FreeMode } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import styles from './styles.module.scss'

const CareersPositions = ({ activeCategory, sortedOffers, onSetCategory }) => {
	return (
		<Box pb="120px">
			<Heading
				mx={{ base: 5, lg: '160px' }}
				pb={4}
				mb={[12, null, '130px']}
				borderBottom="1px solid #6B6B6B"
				fontSize={['40px', null, '100px']}
				lineHeight={{ md: '140px' }}
			>
				Open Positions
			</Heading>
			<Box pl={{ base: 5, lg: '160px' }}>
				<Swiper
					slidesPerView={'auto'}
					speed={1000}
					freeMode={true}
					className="offers-slider"
					modules={[FreeMode]}
				>
					{Object.keys(sortedOffers).map(category => (
						<SwiperSlide
							key={category + Math.random()}
							style={{ width: 'auto' }}
						>
							<Flex
								gap={1}
								onClick={() => onSetCategory(category)}
								pr={[6, null, 12]}
								cursor="pointer"
							>
								<Text
									textTransform="capitalize"
									variant={activeCategory === category ? 'active' : ''}
									fontSize={{ lg: '1.25em' }}
									lineHeight={{ lg: '2em' }}
								>
									{category}
								</Text>
								<Text
									pt={{ md: '7px' }}
									as="sup"
									fontSize={['0.75em', null, '1em']}
									lineHeight="20px"
									variant={activeCategory === category ? 'active' : ''}
									textDecoration="none"
								>
									{sortedOffers[category].length}
								</Text>
							</Flex>
						</SwiperSlide>
					))}
				</Swiper>
			</Box>

			<Table
				variant="simple"
				width={{ base: 'calc(100% - 40px)', lg: 'calc(100% - 320px)' }}
				mx="auto"
			>
				<Thead>
					<Tr>
						<Th
							pt={{ base: '50px', lg: '80px' }}
							border="none"
							mb={-4}
							px={0}
							color="#7F8392"
							fontSize={['0.75em', null, '0.9em']}
							lineHeight="20px"
							w={{ md: '46%' }}
						>
							Position
						</Th>
						<Th
							pt={{ base: '50px', lg: '80px' }}
							border="none"
							mb={-4}
							pl={'19px'}
							color="#7F8392"
							fontSize={['0.75em', null, '0.9em']}
							lineHeight="20px"
							display={{ base: 'none', sm: 'block' }}
						>
							Location
						</Th>
					</Tr>
				</Thead>
				<Tbody>
					{sortedOffers[activeCategory].map(offer => (
						<Tr
							className={styles.tr}
							key={offer.id + offer.position}
							_hover={{
								borderBottomColor: '#fff',
							}}
							borderBottom="1px solid #6B6B6B"
						>
							<LinkBox
								as={Td}
								py={[9, null, 12]}
								px={0}
								border="none"
								w={{ md: '46%' }}
							>
								<LinkOverlay href={offer.url}>
									<Text
										color="#EFEFEF"
										fontSize={{ lg: '18px' }}
										lineHeight={{ lg: '38px' }}
									>
										{offer.position}
									</Text>
								</LinkOverlay>
							</LinkBox>
							<LinkBox
								as={Td}
								py={[9, null, 12]}
								border="none"
								display={{ base: 'none', sm: 'block' }}
							>
								<LinkOverlay href={offer.url}>
									<Flex justifyContent="space-between">
										<Text fontSize={{ lg: '18px' }} lineHeight={{ lg: '38px' }}>
											{offer.location}
										</Text>
										<Image
											className={styles.arrow}
											display="none"
											src="/images/careers/arrow.svg"
											alt="arrow"
											w={{ md: '20px', lg: '28px' }}
										/>
									</Flex>
								</LinkOverlay>
							</LinkBox>
						</Tr>
					))}
					<Tr
						className={styles.tr}
						_hover={{
							borderBottomColor: '#fff',
						}}
						borderBottom="1px solid #6B6B6B"
					>
						<LinkBox
							as={Td}
							py={[9, null, 12]}
							px={0}
							border="none"
							w={{ md: '46%' }}
						>
							<LinkOverlay href="mailto:info@holywater.tech">
								<Text
									color="#EFEFEF"
									fontSize={{ lg: '18px' }}
									lineHeight={{ lg: '38px' }}
								>
									Don’t see a role that fits?
								</Text>
							</LinkOverlay>
						</LinkBox>
						<LinkBox
							as={Td}
							py={[9, null, 12]}
							border="none"
							display={{ base: 'none', sm: 'block' }}
						>
							<LinkOverlay href="mailto:info@holywater.tech">
								<Flex justifyContent="space-between">
									<Text
										fontSize={{ lg: '18px' }}
										lineHeight={{ lg: '38px' }}
									></Text>
									<Image
										className={styles.arrow}
										display="none"
										src="/images/careers/arrow.svg"
										alt="arrow"
										w={{ md: '20px', lg: '28px' }}
									/>
								</Flex>
							</LinkOverlay>
						</LinkBox>
					</Tr>
				</Tbody>
			</Table>
		</Box>
	)
}

export default CareersPositions
