import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

import { components } from './components'

const config = {
	initialColorMode: 'dark',
}

const breakpoints = createBreakpoints({
	sm: '30em',
	md: '48em',
	lg: '80em',
})

const overrides = {
	config,
	components,
	breakpoints,
}

export default extendTheme(overrides)
